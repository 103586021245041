<template>
  <main class="company-profile">
    <div class="about-banner"></div>
    <div class="none-about"></div>
    <div class="about-opacity" style="height: 906px"></div>
    <div class="about-main clearfix">
      <div class="news-column">
        <div class="column-title">
          关于我们
        </div>
        <div class="children-list">
          <div class="item"><router-link to="/about/company">公司介绍</router-link></div>
          <div class="item list-active"><router-link to="/about/presence">员工风采</router-link></div>
        </div>
      </div>

      <div class="company-content">
        <div class="headline-box">
          <div class="title-lef">
            <div class="title-cn">员工风采</div>
            <div class="title-en">EMPLOYEE PRESENCE</div>
          </div>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>当前位置：</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>员工风采</el-breadcrumb-item>
          </el-breadcrumb>
        </div>

        <div class="presence-list clearfix" v-if="staffList">
          <div class="presence-item" v-for="(staff, index) in staffList">
            <img v-if="staff.content_image_list" :src="staff.content_image_list[0].url" />
            <img v-else src="../../assets/images/deaflut-avater.jpg" />
<!--            <div class="presence-name">-->
<!--              <span>{{staff.sub_title}}</span>-->
<!--              <span>{{staff.title}}</span>-->
<!--            </div>-->
          </div>
        </div>
        <xgj-empty v-if="staffList.length === 0" title="员工风采暂无数据" height="800px"></xgj-empty>
        <div class="web-pages" v-if="detail_paging.total_elements > 0">
          <el-pagination
            background
            @size-change="sizeDetailChange"
            @current-change="currentDetailChange"
            :current-page.sync="detail_paging.page"
            :page-sizes="[9]"
            :page-size="detail_paging.size"
            :total="detail_paging.total_elements"
            layout="total, sizes, prev, pager, next, jumper">
          </el-pagination>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'staff-presence',
    data() {
      return {
        staffList: [],
        detail_paging: {  //分页
          page: 1,
          size: 9,
          total_elements: 0
        },
      }
    },
    created() {
      this.getFuneralstaffDetail()
    },
    methods: {
      //改变每页数量
      sizeDetailChange(size) {
        this.detail_paging.size = size;
        this.getFuneralstaffDetail()
      },
      //改变页数
      currentDetailChange(page) {
        this.detail_paging.page = page;
        this.getFuneralstaffDetail()
      },
      getFuneralstaffDetail() {
        const that = this;
        const loading = this.$loading({
          lock: true,
          text: '加载中',
          background: 'rgba(255, 255, 255, .8)'
        });
        let paramsPeople = {
          partnerId: that.$store.state.user.partnerId,
          name: '员工风采',
          page: that.detail_paging.page,
          rows: that.detail_paging.size
        };
        that.$api.website.getFuneralstaffDetail(paramsPeople)
          .then( datas => {
            that.staffList = datas.data;
            that.detail_paging.size = datas.paging.page_size;
            that.detail_paging.page = datas.paging.current_page;
            that.detail_paging.total_elements = datas.paging.records;
            loading.close()
          }).catch( err => {
            loading.close()
        });
      }
    }
  }
</script>

<style lang="scss" scoped>
/deep/ .el-breadcrumb {
  margin-right: 48px;
  position: absolute;
  right: 48px;
  top: 36px;
  .el-breadcrumb__inner {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #000000;
  }
}
@import "./about-us.scss";
</style>
